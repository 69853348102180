<template>
  <div class="row">
<!--    <div class="col-lg-12" v-if="isItclientRF">-->
<!--      <ul class="nav nav-tabs row m-0" role="tablist">-->
<!--        <li class="nav-item">-->
<!--          <a-->
<!--            class="nav-link"-->
<!--            data-toggle="tab"-->
<!--            href="#tab1"-->
<!--            role="tab"-->
<!--            :class="{ active: currentTab === 0 }"-->
<!--            @click="currentTab = 0"-->
<!--          >-->
<!--            <i class="fa fa-align-justify"></i>-->
<!--            {{ $t('clientsRF.table') }}-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a-->
<!--            class="nav-link"-->
<!--            data-toggle="tab"-->
<!--            href="#tab2"-->
<!--            role="tab"-->
<!--            :class="{ active: currentTab === 1 }"-->
<!--            @click="currentTab = 1"-->
<!--          >-->
<!--            <i class="fa fa-align-justify"></i>-->
<!--            {{ $t('clientsRF.rating') }}-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <br />-->
<!--      <div class="row filter-block" v-if="currentTab === 0">-->
<!--        <div class="filters">-->
<!--          <div class="filters__search">-->
<!--            <div class="input-group input-group-sm filters__input filters__input&#45;&#45;clients">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                <i class="fa fa-search" v-else></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('general.search')"-->
<!--                @keyup.13="searchInputHandler(searchValue)"-->
<!--                class="form-control"-->
<!--                v-model="searchValue"-->
<!--                @input="searchInputHandler(searchValue)"-->
<!--              />-->
<!--              <span class="clear-input text-muted">-->
<!--                <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="searchInputHandler('')"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div @change="selectLocationInClientRFLocationSelector" class="col-lg-4 col-md-12">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="input-group input-group-sm filters__location mr-1">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <select class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="filters.location">&ndash;&gt;-->
<!--&lt;!&ndash;                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations')&ndash;&gt;-->
<!--&lt;!&ndash;                    }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="selectLocationInClientRFLocationSelector"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--                                                <div class=" ml-1 input-group-for-location-select-in-monitoringClientRF&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringClientRF&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFiltersInClientRFLocationSelector"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="selectLocationInFiltersInClientRFLocationSelector"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->


<!--          </div>-->

<!--          <div class="filters__multiple">-->
<!--            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'CONNECTED' }">-->
<!--                <input-->
<!--                  type="radio"-->
<!--                  :disabled="isLoadingCLients"-->
<!--                  name="statusFilter"-->
<!--                  @click="setFilter('statusFilter', 'CONNECTED')"-->
<!--                />-->
<!--                {{ $t('clients.connected') }}-->
<!--              </label>-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'DISCONNECTED' }">-->
<!--                <input-->
<!--                  type="radio"-->
<!--                  name="statusFilter"-->
<!--                  :disabled="isLoadingCLients"-->
<!--                  @click="setFilter('statusFilter', 'DISCONNECTED')"-->
<!--                />-->
<!--                {{ $t('clients.disconnected') }}-->
<!--              </label>-->
<!--            </div>-->

<!--            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '2.4' }">-->
<!--                <input-->
<!--                  :disabled="isLoadingCLients || filters.typeFilter == 'wired'"-->
<!--                  type="radio"-->
<!--                  name="frequencyFilter"-->
<!--                  @click="setFilter('frequencyFilter', '2.4')"-->
<!--                />-->
<!--                2.4 {{ $t('aps.ghz') }}-->
<!--              </label>-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '5' }">-->
<!--                <input-->
<!--                  :disabled="isLoadingCLients || filters.typeFilter == 'wired'"-->
<!--                  type="radio"-->
<!--                  name="frequencyFilter"-->
<!--                  @click="setFilter('frequencyFilter', '5')"-->
<!--                />-->
<!--                5 {{ $t('aps.ghz') }}-->
<!--              </label>-->
<!--            </div>-->

<!--            <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.authorizedFilter == 'true' }">-->
<!--                <input-->
<!--                  :disabled="isLoadingCLients"-->
<!--                  type="radio"-->
<!--                  name="authorizedFilter"-->
<!--                  @click="setFilter('authorizedFilter', 'true')"-->
<!--                />-->
<!--                {{ $t('clients.authorized') }}-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-4 col-sm-4"></div>-->
<!--      </div>-->
<!--      <div class="row filter-block" v-if="currentTab === 1">-->
<!--        <div class="col-lg-8 d-flex align-items-end">-->
<!--          <div class="input-group flex-column mr-1 limit-pagination-width">-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                <i class="fa fa-search" v-else></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="searchRFP"-->
<!--                name="search"-->
<!--                :placeholder="$t('general.search')"-->
<!--                @keyup.13="searchInputHandlerForRFP(searchValueForRFP)"-->
<!--                class="form-control"-->
<!--                v-model="searchValueForRFP"-->
<!--                @input="searchInputHandlerForRFP(searchValueForRFP)"-->
<!--              />-->
<!--              <span class="clear-input text-muted">-->
<!--                <i-->
<!--                  class="fa fa-remove"-->
<!--                  v-if="searchValueForRFP && !searchSpinner"-->
<!--                  @click="searchInputHandlerForRFP('')"-->
<!--                ></i>-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="page-offset-no-display input-group flex-column mr-1 limit-pagination-width">-->
<!--            <label for="limitRFP" class="mb-0">-->
<!--              <small>{{ $t('clientsRF.numberOfEntries') }}</small>-->
<!--            </label>-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                <i class="fa fa-list" v-else></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="limitRFP"-->
<!--                name="limitRFP"-->
<!--                class="form-control"-->
<!--                v-model.number="limitRFP"-->
<!--                type="number"-->
<!--                min="1"-->
<!--                @input="limitInputHandlerForRFP"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div @change="selectLocationForRFP" class="col-lg-4 col-md-12">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="input-group input-group-sm filters__location mr-1">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <select class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="filters.location">&ndash;&gt;-->
<!--&lt;!&ndash;                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations')&ndash;&gt;-->
<!--&lt;!&ndash;                    }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="selectLocationForRFP"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->


<!--                                                              <div class="input-group-for-location-select-in-monitoringClientRF&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringClientRF&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFiltersInClientRFForRFP"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="selectLocationInFiltersInClientRFForRFP"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->


<!--        </div>-->
<!--        <div class="col-lg-4 col-sm-4"></div>-->
<!--      </div>-->
<!--                       <div v-if="isFiltersActive" class="mb-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--    </div>-->



    <div class="col-lg-12" v-if="isItclientRF">
      <ul class="nav nav-tabs row m-0" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 0 }"
            @click.prevent="setCurrentTabTo0"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('clientsRF.table') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 1 }"
            @click.prevent="setCurrentTabTo1"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('clientsRF.rating') }}
          </a>
        </li>
      </ul>
      <br />
      <div class="row filter-block" v-if="currentTab === 0">
        <div class="w-100">
          <div class="row">
            <div class="col-lg-3 mb-h pr-0">
                          <div class="input-group input-group-sm filters__input filters__input--clients">
              <span class="input-group-addon">
                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                <i class="fa fa-search" v-else></i>
              </span>
              <input
                id="search"
                name="search"
                :placeholder="$t('general.search')"
                @keyup.13="searchInputHandler(searchValue)"
                class="form-control"
                v-model="searchValue"
                @input="searchInputHandler(searchValue)"
              />
              <span class="clear-input text-muted">
                <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="searchInputHandler('')"></i>
              </span>
            </div>
            </div>

            <div class="col-lg-3 mb-h pr-0" v-if="areLocationsVisible">
                                                            <div class="  input-group-for-location-select-in-monitoringClientRF--filters">


                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringClientRF--filters"
                    enable-max-option-width
                    small-size
                     :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFiltersInClientRFLocationSelector"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectWithChildsInFiltersInClientRFLocationSelector"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


              </div>
            </div>


          </div>
<!--          <div class="filters__search">-->






<!--          </div>-->

          <div class="filters__multiple">
            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
              <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'CONNECTED' }">
                <input
                  type="radio"
                  :disabled="isLoadingCLients"
                  name="statusFilter"
                  @click="setFilter('statusFilter', 'CONNECTED')"
                />
                {{ $t('clients.connected') }}
              </label>
              <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'DISCONNECTED' }">
                <input
                  type="radio"
                  name="statusFilter"
                  :disabled="isLoadingCLients"
                  @click="setFilter('statusFilter', 'DISCONNECTED')"
                />
                {{ $t('clients.disconnected') }}
              </label>
            </div>

            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
              <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '2.4' }">
                <input
                  :disabled="isLoadingCLients || filters.typeFilter == 'wired'"
                  type="radio"
                  name="frequencyFilter"
                  @click="setFilter('frequencyFilter', '2.4')"
                />
                2.4 {{ $t('aps.ghz') }}
              </label>
              <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '5' }">
                <input
                  :disabled="isLoadingCLients || filters.typeFilter == 'wired'"
                  type="radio"
                  name="frequencyFilter"
                  @click="setFilter('frequencyFilter', '5')"
                />
                5 {{ $t('aps.ghz') }}
              </label>
            </div>

            <div class="btn-group btn-group-sm float-left" data-toggle="button">
              <label class="btn btn btn-outline-info" :class="{ active: filters.authorizedFilter == 'true' }">
                <input
                  :disabled="isLoadingCLients"
                  type="radio"
                  name="authorizedFilter"
                  @click="setFilter('authorizedFilter', 'true')"
                />
                {{ $t('clients.authorized') }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-4"></div>
      </div>
      <div class="row filter-block" v-if="currentTab === 1">
        <div class="col-lg-12 p-0 d-flex align-items-end">


          <div class="col-lg-3 mb-h pr-0 pl-0">
                    <div class="input-group flex-column mr-1 limit-pagination-width">
            <div class="input-group input-group-sm">
              <span class="input-group-addon">
                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                <i class="fa fa-search" v-else></i>
              </span>
              <input
                id="searchRFP"
                name="search"
                :placeholder="$t('general.search')"
                @keyup.13="searchInputHandlerForRFP(searchValueForRFP)"
                class="form-control"
                v-model="searchValueForRFP"
                @input="searchInputHandlerForRFP(searchValueForRFP)"
              />
              <span class="clear-input text-muted">
                <i
                  class="fa fa-remove"
                  v-if="searchValueForRFP && !searchSpinner"
                  @click="searchInputHandlerForRFP('')"
                ></i>
              </span>
            </div>
          </div>
          </div>

          <div class="col-lg-3 mb-h pr-0 " v-if="areLocationsVisible">
                                                                        <div class="input-group-for-location-select-in-monitoringClientRF--filters">


                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
:show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringClientRF--filters"
                    enable-max-option-width
                    small-size
                      :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"

                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFiltersInClientRFForRFP"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectWithChildsInFiltersInClientRFForRFP"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


              </div>
          </div>



<!--          <div class="page-offset-no-display input-group flex-column mr-1 limit-pagination-width">-->
<!--            <label for="limitRFP" class="mb-0">-->
<!--              <small>{{ $t('clientsRF.numberOfEntries') }}</small>-->
<!--            </label>-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                <i class="fa fa-list" v-else></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="limitRFP"-->
<!--                name="limitRFP"-->
<!--                class="form-control"-->
<!--                v-model.number="limitRFP"-->
<!--                type="number"-->
<!--                min="1"-->
<!--                @input="limitInputHandlerForRFP"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->






        </div>
        <div class="col-lg-4 col-sm-4"></div>
      </div>
      <div v-if="isFiltersActive" class="mb-1">
        <WButton secondary outline sm
          @click="resetAllFilters"
        >
          <i class="fa fa-close"></i>
          {{ $t('general.resetAllFilters') }}
        </WButton>
      </div>
    </div>





    <div class="tab-content col-lg-12 clear-background-and-clear-padding-for-clients-rf">
      <div class="tab-pane" v-if="isItclientRF" id="tab1" role="tabpanel" :class="{ active: currentTab === 0 }">
        <div class="client" :class="{ 'edit-visible': editVisible }">
          <div class="client-list animated fadeIn">
            <div class="animated fadeIn sticky-top">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <!--                    <div class="loader loader-backdrop loader-backdrop-clientsRF" v-if="isLoadingCLients"></div>-->
                    <div v-if="isLoadingCLients"><Loader-spinner /></div>
                    <div class="card-block">
                      <ClientRFTable
                      :items="clientsList || []"
                      :selectedRowClientRFIds="[]"
                      :activeRowClientRFMac="clientSelectedMac"
                      :isDataLoading="false"
                      :isDisabled="false"
                      :isOneColMode="Boolean(editVisible)"
                      :isSelectionRowsModeEnabled="false"
                      @on-cell-click="toggleEdit"
                    >
                      <template v-slot:table-menu>
                        <div class="control-panel">
                          <div class="control-panel__container left">
                            <div class="d-flex align-items-center justify-content-between">
                              <div>
                                <i class="fa fa-align-justify"></i>
                                {{ $t('clientsRF.clientRFTableTitle') }}
                                <span class="text-muted ml-1" v-if="!editVisible && !isLoadingCLients && clientsList.length">
                                  {{ $t('clientsRF.tableView') }} {{ clientsList.length }}
                                  {{ $t('clientsRF.tableItemsOf') }} {{ totalResult }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="control-panel__container right">
                          </div>
                        </div>
                      </template>
                      <template v-slot:emptystate>
                        <div v-if="!clientsList.length && filters.statusFilter === 'CONNECTED'"
                          class="emptystate-table-row muted"
                        >
                          {{ $t('clients.noConnectedClientsToDisplay') }}
                          <WButton link sm
                            @click.prevent="showAllClients"
                          >
                            {{ $t('clients.showAllClients') }}
                          </WButton>
                        </div>

                        <div v-else class="muted">
                          {{ $t('general.noDataToDisplay') }}
                        </div>
                      </template>
                      <!--
                        todo переписать пагинацию на нативную с рефакторингом запросов.
                        Пока что нативная из ClientRFTable несовместима с логикой запросов
                      -->
                      <template v-slot:table-actions-bottom>
                        <div
                          v-show="
                            canLoadMore &&
                            clientsList.length &&
                            !isLoadingCLients &&
                            totalResult - clientsList.length
                          "
                          class="card-block text-center p-0"
                        >
                          <WButton link @click="loadMore">
                            {{ $t('clientsRF.paginationLoad') }}
                            <span v-if="limit < totalResult - clientsList.length">
                              {{ limit }}
                            </span>
                            <span v-else>
                              {{ totalResult - clientsList.length }}
                            </span>
                            {{ $t('clientsRF.paginationClientsOutOf') }} {{ totalResult - clientsList.length }}
                          </WButton>
                        </div>
                      </template>
                    </ClientRFTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="client-edit" v-if="editVisible">
            <div class="row sticky-top">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-clientsRF" v-if="isClientRFLoading"></div>-->
                  <div v-if="isClientRFLoading || isClientRFRemainingDataLoading"><Loader-spinner /></div>
                  <div class="card-header d-flex align-items-center">
                    <span class="h4">
                      {{ getFormatMac(clientEditCurrent.mac) }}
                    </span>
                    <WButton secondary outline sm
                      customClass="ml-auto"
                      @click="disableEditMode"
                    >
                      <i class="fa fa-close"></i>
                    </WButton>
                  </div>
                  <div class="card-block pt-0">
                    <div class="row">
                      <div class="col-lg-12">
                        <date-picker
                          class="float-right"
                          v-if="datepickerVisible"
                          v-model="drPickerDateForRF"
                          v-on:input="datePickerChangedDate"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div v-if="clientRFData && clientRFData.length > 0">
                          <highcharts :options="chartData" ref="highcharts"></highcharts>
                          <transition name="fade">
                            <div v-if="this.wifiRateInfoForSelectedPoint !== null">
                              <div>
                                <b>{{ $t('clientsRF.rx') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.rx }}
                                </div>
                              </div>
                              <div>
                                <b>{{ $t('clientsRF.tx') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.tx }}
                                </div>
                              </div>
                              <div v-if="this.wifiRateInfoForSelectedPoint.throughput !== 0">
                                <b>{{ $t('clientsRF.expectedThroughput') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.throughput }}
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div>
                        <div class="w-100 d-flex justify-content-center" v-else>
                          <div>
                            {{ $t('general.noDataToDisplay') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="actions-buttons">
                    <div>
                      <WButton secondary outline
                        @click="disableEditMode"
                      >
                        {{ $t('general.close') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" v-if="isItclientRF" id="tab2" role="tabpanel" :class="{ active: currentTab === 1 }">
        <div class="client" :class="{ 'edit-visible': editVisibleRFP }">
          <div class="client-list animated fadeIn">
            <div class="animated fadeIn sticky-top">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="loader loader-backdrop loader-backdrop-clientsRF" v-if="isLoadingClientsRFPList"></div>
                    <div class="card-block">
                      <ClientRFPTable
                        :items="clientsRFPList || []"
                        :selectedRowClientRFPIds="[]"
                        :activeRowClientRFPMac="clientSelectedForRFPMac"
                        :isDataLoading="false"
                        :isDisabled="false"
                        :isOneColMode="Boolean(editVisibleRFP)"
                        :isSelectionRowsModeEnabled="false"
                        @on-cell-click="toggleEditRFP"
                      >
                        <template v-slot:table-menu>
                          <div class="control-panel">
                            <div class="control-panel__container left">
                              <div class="d-flex align-items-center justify-content-between">
                                <div>
                                  <i class="fa fa-align-justify"></i>
                                  {{ $t('clientsRF.clientRFP') }}
                                  <span class="text-muted ml-1" v-if="!editVisibleRFP && !isLoadingClientsRFPList && clientsRFPList.length">
                                    {{ $t('clientsRF.tableView') }} {{ clientsRFPList.length }}
                                    {{ $t('clientsRF.tableItemsOf') }} {{ totalResultRFP }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="control-panel__container right">
                              <date-picker
                                v-if="!editVisibleRFP"
                                v-model="drPickerDateForRFP"
                                v-on:input="datePickerChangedDateForRFP"
                              ></date-picker>
                            </div>
                          </div>
                        </template>
                        <template v-slot:emptystate>
                          <div class="muted">
                            {{ $t('general.noDataToDisplay') }}
                          </div>
                        </template>
                        <!--
                          todo переписать пагинацию на нативную с рефакторингом запросов.
                          Пока что нативная из ClientRFPTable несовместима с логикой запросов
                        -->
                        <template v-slot:table-actions-bottom>
                          <div
                            v-if="
                              canLoadMoreRFP &&
                              clientsRFPList.length &&
                              !isLoadingClientsRFPList &&
                              totalResultRFP - clientsRFPList.length
                            "
                            class="card-block text-center p-0"
                          >
                            <WButton link
                              @click="loadMoreRFP"
                            >
                              {{ $t('clientsRF.paginationLoad') }}
                              <span v-if="limitRFP < totalResultRFP - clientsRFPList.length">
                                {{ limitRFP }}
                              </span>
                              <span v-else>
                                {{ totalResultRFP - clientsRFPList.length }}
                              </span>
                              {{ $t('clientsRF.paginationClientsOutOf') }}
                              {{ totalResultRFP - clientsRFPList.length }}
                            </WButton>
                          </div>
                        </template>
                      </ClientRFPTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="client-edit" v-if="editVisibleRFP">
            <div class="row sticky-top">
              <div class="col-lg-12">
                <div class="card">
                  <div
                    class="loader loader-backdrop loader-backdrop-clientsRF"
                    v-if="isClientRFLoading || isClientRFRemainingDataLoading"
                  ></div>
                  <div class="card-header d-flex align-items-center">
                    <span class="h4">
                      {{ getFormatMac(clientEditCurrentForRFP.mac) }}
                    </span>
                    <WButton secondary outline sm
                      customClass="ml-auto"
                      @click="disableEditModeForRFP"
                    >
                      <i class="fa fa-close"></i>
                    </WButton>
                  </div>
                  <div class="card-block pt-0">
                    <div class="row">
                      <div class="col-lg-12">
                        <date-picker
                          class="float-right"
                          v-if="datepickerVisible"
                          v-model="drPickerDateForRF"
                          v-on:input="datePickerChangedDate"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div v-if="clientRFData && clientRFData.length > 0">
                          <highcharts :options="chartData" ref="highcharts"></highcharts>
                          <transition name="fade">
                            <div v-if="this.wifiRateInfoForSelectedPoint !== null">
                              <div>
                                <b>{{ $t('clientsRF.rx') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.rx }}
                                </div>
                              </div>
                              <div>
                                <b>{{ $t('clientsRF.tx') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.tx }}
                                </div>
                              </div>
                              <div v-if="this.wifiRateInfoForSelectedPoint.throughput !== 0">
                                <b>{{ $t('clientsRF.expectedThroughput') }}</b>
                                <div>
                                  {{ this.wifiRateInfoForSelectedPoint.throughput }}
                                </div>
                              </div>
                            </div>
                          </transition>
                        </div>
                        <div class="w-100 d-flex justify-content-center" v-else>
                          <div>
                            {{ $t('general.noDataToDisplay') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="actions-buttons">
                    <div>
                      <WButton secondary outline
                        @click="disableEditModeForRFP"
                      >
                        {{ $t('general.close') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

import commonService from '@/services/commonService';
import clientService from '@/services/clientService';
import locationService from '@/services/locationService';

import Filters from '@/filters';
import helpers from '@/helpers';
import DatePicker from '@/components/date-picker.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import SelectComponentV2 from '@/components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

import {
  ClientRFTable,
  ClientRFPTable
} from './components';

import { intervalUpdateModeMixin } from '@/mixins';


Vue.use(require('vue-moment'));

export default {
  name: 'ClientRF',
  components: {
    DatePicker,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    ClientRFTable,
    ClientRFPTable
  },
  mixins: [
    intervalUpdateModeMixin
  ],
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      currentTab: 0,
      datepickerVisible: true,
      selectedPointTimestamp: 0,
      drPickerDateForRF: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForRFP: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      clientRFData: [],
      limit: 100,
      offset: 0,
      limitRFP: 100,
      offsetRFP: 0,
      delayTimer: null,
      searchValue: '',
      searchValueForRFP: '',
      searchSpinner: '',
      totalResult: false,
      canLoadMore: true,
      canLoadMoreRFP: true,
      isVideoModalOpened: false,
      enableSaveChanges: false,
      showColumnSwitcher: false,
      filters: {
        typeFilter: '',
        statusFilter: 'CONNECTED',
        frequencyFilter: '',
        query: '',
        authorizedFilter: '',
        healthStatusFilter: '',
        location: 'All locations',
        with_childs: true
      },
      filtersRFP: {
        query: ''
      },
      updatedClient: {
        data: {
          description: '',
          rtsp_stream: 'rtsp_stream'
        },
        Type: ''
      },
      clientAuthorization: {
        username: 'Username',
        ua_type: 'Type',
        ua_device: 'Device',
        os: 'OS',
        os_version: 'OS version',
        useragent: 'Useragent'
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    // isFiltersActive() {
    //   if (this.currentTab === 0) {
    //     return this.filters.typeFilter !== 'other' ||
    //       this.filters.statusFilter !== 'CONNECTED' ||
    //       this.filters.frequencyFilter !== '' ||
    //       this.filters.query !== '' ||
    //       this.filters.authorizedFilter !== '' ||
    //       this.filters.healthStatusFilter !== '' ||
    //       this.filters.location !== 'All locations' || this.searchValue !== ''
    //
    //   } else if (this.currentTab === 1) {
    //     // console.log('this.filtersRFP.query', this.filtersRFP.query)
    //      // console.log('searchValueForRFP', this.searchValueForRFP)
    //      // console.log('this.filters.location', this.filters.location)
    //     return this.filtersRFP.query !== '' ||
    //       this.searchValueForRFP !== '' ||
    //       this.filters.location !== 'All locations'
    //
    //   }
    //   return true;
    // },

    isFiltersActive() {
      if (this.currentTab === 0) {
        return this.filters.typeFilter !== 'other' ||
          this.filters.statusFilter !== 'CONNECTED' ||
          this.filters.frequencyFilter !== '' ||
          this.filters.query !== '' ||
          this.filters.authorizedFilter !== '' ||
          this.filters.healthStatusFilter !== '' ||
          this.searchValue !== '' ||
          (this.areLocationsVisible ? this.filters.location !== 'All locations' : false)

      } else if (this.currentTab === 1) {
        // console.log('this.filtersRFP.query', this.filtersRFP.query)
         // console.log('searchValueForRFP', this.searchValueForRFP)
         // console.log('this.filters.location', this.filters.location)
        return this.filtersRFP.query !== '' ||
          this.searchValueForRFP !== '' ||
          (this.areLocationsVisible ? this.filters.location !== 'All locations' : false)

      }
      return true;
    },

    isClientRFRemainingDataLoading() {
      return this.$store.state.loadingClientRFRemainingData;
    },
    clientRFDataByTimestamp() {
      const clientRFDataByTimestamp = {};
      for (const item of this.clientRFData) {
        clientRFDataByTimestamp[item.ts] = item.rf;
      }
      return clientRFDataByTimestamp;
    },

    wifiRateInfoForSelectedPoint() {
      const timestamp = this.selectedPointTimestamp;
      if (this.clientRFDataByTimestamp[timestamp]) {
        const selectedPointRFData = this.clientRFDataByTimestamp[timestamp];
        const Info = this.formatWifiRate(selectedPointRFData);
        return Info;
      }
      return null;
    },
    chartData() {
      const chartData = {
        time: {
          useUTC: false
        },
        chart: {
          type: 'area',
          spacingBottom: 1,
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: this.$t('clientsRF.clientRFChartTitle')
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        xAxis: {
          type: 'datetime',
          crosshair: true
        },
        yAxis: {
          title: {
            text: 'dBm'
          },
          max: 0,
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          // конструирование функции для вывода тулбара (tooltip.formater()) вынесенно в метод
          // this.createChartToolbarFormatterWithLocalization().
          // Поскольку в этой функции нужен контекст графика (например this.points[1].y) и одновременно контекст
          // этого компонента ( например this.$t('clientsRF.signal')). Поэтому в
          // reateChartToolbarFormatterWithLocalization()
          // создается функция с уже переведенными строками.
          shared: true
        },
        plotOptions: {
          area: {
            fillOpacity: 0.5,
            threshold: null
          },
          series: {
            // gapSize значение в миллисекундах. Сейчас 600000 (10 минут)
            // Если разрыв между 2 соседними точками больше чем в gapSize, то график разрывается.
            // Тут подробнее https://api.highcharts.com/highstock/6.0.5/plotOptions.series.gapSize
            gapSize: 600000,
            gapUnit: 'value'
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.$t('clientsRF.signal'),
            data: [],
            color: '#5c7df8',
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          },
          {
            name: this.$t('clientsRF.noise'),
            data: [],
            color: '#f8b6b0',
            events: {
              legendItemClick(e) {
                e.preventDefault();
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  this.chartMouseOverPoint(e);
                },
                mouseOut: () => {
                  this.selectedPointTimestamp = 0;
                }
              }
            }
          }
        ]
      }
      chartData.tooltip.formatter = this.createChartToolbarFormatterWithLocalization();
      chartData.series[0].data = [];
      chartData.series[1].data = [];
      // this.clientRFDataByTimestamp = {};
      for (const item of this.clientRFData) {
        const dateTime = item.ts * 1000;
        const {signal} = item.rf;
        const {noise} = item.rf;
        chartData.series[0].data.push([dateTime, signal]);
        chartData.series[1].data.push([dateTime, noise]);
      }
      return chartData;
    },
    isClientRFLoading() {
      return this.$store.state.loadingclientRF;
    },
    isItclientRF() {
      return this.$route.name === 'ClientRF';
    },
    editVisible() {
      return typeof this.$store.state.editClientIndex === 'string' && this.$store.state.editClientIndex.length;
    },
    editVisibleRFP() {
      return (
        typeof this.$store.state.editClientForRFPIndex === 'string' && this.$store.state.editClientForRFPIndex.length
      );
    },
    clientsList() {
      return this.$store.state.clientsList;
    },
    clientsRFPList() {
      return this.$store.state.clientsRFPList;
    },
    isLoadingClientsRFPList() {
      return this.$store.state.loadingClientsRFP;
    },
    clientsRFPListByClientID() {
      const result = {};
      if (this.clientsRFPList && this.clientsRFPList.length > 0) {
        for (const client of this.clientsRFPList) {
          result[client.mac] = client;
        }
      }
      return result;
    },
    clientSelectedMac() {
      return this.$store.state.editClientMac;
    },
    clientSelectedForRFPMac() {
      return this.$store.state.editClientForRFPMac;
    },
    isLoadingCLients() {
      return this.$store.state.loadingClients;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    clientEditCurrent() {
      const { clientsList } = this.$store.state;
      let currentClient;
      clientsList.forEach((client) => {
        if (client.mac == this.$store.state.editClientMac) {
          currentClient = client;
        }
      });
      if (currentClient.Type === 'other') {
        currentClient.data.rtsp_stream = '';
      }
      this.updatedClient = JSON.parse(JSON.stringify(currentClient));
      return currentClient;
    },
    clientEditCurrentForRFP() {
      const { clientsRFPList } = this.$store.state;
      let currentClient;
      clientsRFPList.forEach((client) => {
        if (client.mac == this.$store.state.editClientForRFPMac) {
          currentClient = client;
        }
      });
      if (currentClient.Type === 'other') {
        currentClient.data.rtsp_stream = '';
      }
      // this.updatedClient = JSON.parse(JSON.stringify(currentClient));
      return currentClient;
    }
  },
  watch: {
    currentTab(val) {
      this.disableEditMode();
      this.disableEditModeForRFP();
      this.selectedPointTimestamp = 0;
      this.clientRFData = [];
    },
    limitRFP(newValue, oldValue) {
      if (!parseInt(newValue) || parseInt(newValue) <= 0) {
        // console.log('недопустимо!')
        this.limitRFP = oldValue;
      }
    },
  },
  methods: {
    selectLocationInClientRFLocationSelector() {
      // this.refreshClientsListQuery()
      this.disableEditModeFromSearchInputHandler();
      clientService.refreshClientsV2(this, 'spinner');

    },
    selectLocationForRFP() {
      this.disableEditModeFromSearchInputHandlerForRFP();
      clientService.refreshClientsForRFP(this, 'spinner');
    },

    selectLocationInFiltersInClientRFLocationSelector(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.selectLocationInClientRFLocationSelector()


    },
    selectWithChildsInFiltersInClientRFLocationSelector(){
      this.selectLocationInClientRFLocationSelector();
    },
    setCurrentTabTo0() {
      this.currentTab = 0;
      this.selectLocationInClientRFLocationSelector();

    },
    setCurrentTabTo1() {
      this.currentTab = 1;
      this.selectLocationForRFP();
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   if (this.currentTab === 0) {
    //     this.filters = {
    //       typeFilter: 'other',
    //       statusFilter: 'CONNECTED',
    //       frequencyFilter: '',
    //       query: '',
    //       authorizedFilter: '',
    //       healthStatusFilter: '',
    //       location: 'All locations',
    //       with_childs: true
    //     }
    //     this.searchValue = '';
    //     this.selectLocationInClientRFLocationSelector()
    //
    //   } else if (this.currentTab === 1) {
    //     this.filtersRFP = {
    //       query: ''
    //     }
    //     this.filters.location = 'All locations'
    //     this.searchValueForRFP = '';
    //     this.selectLocationForRFP();
    //   }
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      if (this.currentTab === 0) {
        this.filters = {
          typeFilter: 'other',
          statusFilter: 'CONNECTED',
          frequencyFilter: '',
          query: '',
          authorizedFilter: '',
          healthStatusFilter: '',
          //  location: 'All locations',
          // with_childs: true
        }
        if (!this.isSelectedDefaultLocationForRequests) {
          this.filters.location = 'All locations';
          this.filters.with_childs = true;
          this.locationInFiltersData = [];
        } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
            this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
        // console.log(this.filters)
        this.toggleShowLocationFilter();
        this.searchValue = '';
        this.selectLocationInClientRFLocationSelector()

      } else if (this.currentTab === 1) {
        this.filtersRFP = {
          query: ''
        }
        // this.filters.location = 'All locations'
        if (!this.isSelectedDefaultLocationForRequests) {
          this.filters.location = 'All locations';
        } else {

        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)

        }
        this.searchValueForRFP = '';
        this.selectLocationForRFP();
      }
    },

    selectLocationInFiltersInClientRFForRFP(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.selectLocationForRFP()


    },
    selectWithChildsInFiltersInClientRFForRFP(){
      this.selectLocationForRFP()
    },

    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },

    createChartToolbarFormatterWithLocalization() {
      if (this.$i18n.locale === 'en') {
        return function () {
          const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
          return (
            `<b>${dateTimeToTooltip}</b><br>` +
            `<b>Signal:</b> ${this.points[0].y} dBm <br>` +
            `<b>Noise:</b> ${this.points[1].y} dBm <br><br><hr>` +
            `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
          );
        };
      }
      if (this.$i18n.locale === 'ru') {
        return function () {
          const dateTimeToTooltip = moment(this.x).format(' D MMM YYYY, H:mm:ss ');
          return (
            `<b>${dateTimeToTooltip}</b><br>` +
            `<b>Сигнал:</b> ${this.points[0].y} dBm <br>` +
            `<b>Шум:</b> ${this.points[1].y} dBm <br><br><hr>` +
            `<b>SNR:</b> ${this.points[0].y - this.points[1].y} dBm`
          );
        };
      }
      return null;
    },
    chartMouseOverPoint(event) {
      // console.log(event.target.x / 1000);
      this.selectedPointTimestamp = event.target.x / 1000;
    },
    formatWifiRate(clientRfObject) {
      const rf = clientRfObject;
      const formatedWifiRate = {};
      let rxStr = `${rf.rx_rate / 1000} ${this.$t('general.mbitS')}, ${rf.rx_mhz} ${this.$t('general.mhz')}`;

      if (rf.rx_ht || rf.rx_vht) {
        if (rf.rx_vht) rxStr += `, VHT-MCS ${rf.rx_mcs}`;
        if (rf.rx_nss) rxStr += `, VHT-NSS ${rf.rx_nss}`;
        if (rf.rx_ht) rxStr += `, MCS ${rf.rx_mcs}`;
        if (rf.rx_short_gi) rxStr += ', Short GI';
      }

      let txStr = `${rf.tx_rate / 1000} ${this.$t('general.mbitS')}, ${rf.tx_mhz} ${this.$t('general.mhz')}`;
      if (rf.tx_ht || rf.tx_vht) {
        if (rf.tx_vht) txStr += `, VHT-MCS ${rf.tx_mcs}`;
        if (rf.tx_nss) txStr += `, VHT-NSS ${rf.tx_nss}`;
        if (rf.tx_ht) txStr += `, MCS ${rf.tx_mcs}`;
        if (rf.tx_short_gi) txStr += ', Short GI';
      }
      let throughputStr;
      if (clientRfObject.expected_throughput === 0) {
        throughputStr = 0;
      } else {
        throughputStr = `${clientRfObject.expected_throughput / 1000} ${this.$t('general.mbitS')}`;
      }
      formatedWifiRate.rx = rxStr;
      formatedWifiRate.tx = txStr;
      formatedWifiRate.throughput = throughputStr;
      return formatedWifiRate;
    },

    // getClientRf() {
    //   this.$store.commit('toggleLoadingClientRF');
    //   // const mac = this.$store.state.editClientMac; - так было раньше
    //   // теперь прокидываем mac_addr девайса (из /api/clients)
    //   // чтобы обеспечить маскировку мак адресов у некоторых клиентов
    //   // пользователю приходит или mac или макозаментель в этом поле.
    //   // В зависимости от того есть ли у пользователя флажок mac_mask типа boolean
    //   // const mac = this.$store.state.editClientMacAddr;
    //   // плюс теперь еще смотрим какой таб открыт, Table или Rating чтобы запросить с выбранным маком
    //   let mac;
    //   if (this.currentTab === 0) {
    //     mac = this.$store.state.editClientMacAddr;
    //   }
    //   if (this.currentTab === 1) {
    //     mac = this.$store.state.editClientForRFPMacAddr;
    //   }
    //   // console.log(mac);
    //   let startDate = (this.drPickerDateForRF.startDate / 1000).toFixed(0);
    //   let endDate = (this.drPickerDateForRF.endDate / 1000).toFixed(0);
    //   startDate = parseInt(startDate, 10);
    //   endDate = parseInt(endDate, 10);
    //   // чистим выбранную точку на графике
    //   this.selectedPointTimestamp = 0;
    //   // чистим массив со значениями RF
    //   this.clientRFData = [];
    //   Vue.axios
    //     .post(`${API_URL || ''}/api/client/rf`, {
    //       action: 'R',
    //       sort_by: { field: 'ts', order: 1 },
    //       timebounds: { start: startDate, stop: endDate },
    //       q: { mac: `${mac}` }
    //     })
    //     .then(
    //       (response) => {
    //         if (response.data.status === 'success') {
    //           if (response.data.data.itemslist[0]) {
    //             for (const item of response.data.data.itemslist) {
    //               this.clientRFData.push(item);
    //             }
    //           }
    //           this.$store.commit('toggleLoadingClientRF');
    //         } else if (response.data.status === 'error') {
    //           this.$store.commit('toggleLoadingClientRF');
    //           VueNotifications.error({ message: response.data.description });
    //         }
    //       },
    //       (err) => {
    //         this.$store.commit('toggleLoadingClientRF');
    //         VueNotifications.error({ message: err });
    //       }
    //     );
    // },
    getClientRf() {
      this.$store.commit('toggleLoadingClientRF');
      // const mac = this.$store.state.editClientMac; - так было раньше
      // теперь прокидываем mac_addr девайса (из /api/clients)
      // чтобы обеспечить маскировку мак адресов у некоторых клиентов
      // пользователю приходит или mac или макозаментель в этом поле.
      // В зависимости от того есть ли у пользователя флажок mac_mask типа boolean
      // const mac = this.$store.state.editClientMacAddr;
      // плюс теперь еще смотрим какой таб открыт, Table или Rating чтобы запросить с выбранным маком
      let mac;
      if (this.currentTab === 0) {
        mac = this.$store.state.editClientMacAddr;
      }
      if (this.currentTab === 1) {
        mac = this.$store.state.editClientForRFPMacAddr;
      }
      // console.log(mac);
      let startDate = (this.drPickerDateForRF.startDate / 1000).toFixed(0);
      let endDate = (this.drPickerDateForRF.endDate / 1000).toFixed(0);
      startDate = parseInt(startDate, 10);
      endDate = parseInt(endDate, 10);
      // чистим выбранную точку на графике
      this.selectedPointTimestamp = 0;
      // чистим массив со значениями RF
      this.clientRFData = [];

      //     Vue.axios
      // .post(`${API_URL || ''}/api/client/rf`, {
      //   action: 'R',
      //   sort_by: { field: 'ts', order: 1 },
      //   timebounds: { start: startDate, stop: endDate },
      //   q: { mac: `${mac}` }
      // })
      const paramsForRequest = {
        action: 'R',
        location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
        sort_by: { field: 'ts', order: 1 },
        timebounds: { start: startDate, stop: endDate },
        q: { mac: `${mac}` }
      };

      Vue.axios.post(`${API_URL || ''}/api/client/rf`, paramsForRequest).then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            this.$store.commit('toggleLoadingClientRF');
            // тут может вызывается функция докачивающая данные. Дело в том что бэк отдает только 2000 записей.
            // И при запросе на день этого хватает, а например на неделю нет.
            // Из ручки rf прилетает не больше 2000 записей,
            // Хотя их фактически может быть больше, например 5000 (count: 2000, total: 5000).
            // Эти первые 2000 и отображались.
            // Теперь нализируем  ответ от первого запроса в rf, подсчитывается сколько еще записей не хватает и есть ли вообще такие
            // И инициируется новый запрос для подтягивания недостающих данных по rf (с помощью limit и offset), если нужно

            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getClientRfRemainingData(limit, offset, dataFromResponse, paramsForRequest);
                } else {
                  this.clientRFData = dataFromResponse;
                }
              } else {
                this.clientRFData = dataFromResponse;
              }
            } else {
              this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            this.$store.commit('toggleLoadingClientRF');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          this.$store.commit('toggleLoadingClientRF');
          VueNotifications.error({ message: err });
        }
      );
    },
    getClientRfRemainingData(limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq) {
      // console.log('get remaining data')
      // используется для догрузки данных из ручки rf.
      // Подробнее в комментах к функции getClientRf
      if (!limit || !offset) {
        this.clientRFData = dataFromFirstReq;
        return;
      }
      this.$store.commit('loadingClientRFRemainingDataBegin');
      const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
      praramsForRequest.page = { limit, offset };

      // чистим массив со значениями RF
      this.clientRFData = [];
      Vue.axios.post(`${API_URL || ''}/api/client/rf`, praramsForRequest).then(
        (response) => {
          if (response.data.status === 'success') {
            if (response.data.data.itemslist[0]) {
              for (const item of response.data.data.itemslist) {
                dataFromFirstReq.push(item);
              }
            }
            this.clientRFData = dataFromFirstReq;
            this.$store.commit('loadingClientRFRemainingDataEnd');
          } else if (response.data.status === 'error') {
            this.clientRFData = dataFromFirstReq;
            this.$store.commit('loadingClientRFRemainingDataEnd');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          this.clientRFData = dataFromFirstReq;
          this.$store.commit('loadingClientRFRemainingDataEnd');
          VueNotifications.error({ message: err });
        }
      );
    },
    datePickerChangedDate(e) {
      this.drPickerDateForRF.startDate = e.startDate;
      this.drPickerDateForRF.endDate = e.endDate;
      this.getClientRf();
    },
    datePickerChangedDateForRFP(e) {
      this.drPickerDateForRFP.startDate = e.startDate;
      this.drPickerDateForRFP.endDate = e.endDate;
      // еще ставим дату для datepicker который запрашивает данные RF для графика
      this.drPickerDateForRF.startDate = e.startDate;
      this.drPickerDateForRF.endDate = e.endDate;
      this.datePickerInputHandlerForRFP();
    },
    toggleEdit(selectedTableRowData) {
      const { originalIndex, nativeData: { mac, mac_addr } } = selectedTableRowData;

      // legacy
      // чтобы в датапикере перерисовалась дата скрываем его, а потом показываем
      this.datepickerVisible = false;
      // this.$set(this.drPickerDateForRF, 'startDate', moment().startOf('hour').valueOf());
      // this.$set(this.drPickerDateForRF, 'endDate', moment().endOf('hour').valueOf());
      this.$store.commit('setClientEditIndex', String(originalIndex));
      this.$store.commit('setClientEditMac', mac);
      this.$store.commit('setClientEditMacAddr', mac_addr);
      this.$nextTick(() => {
        // чтобы в датапикере перерисовалась дата
        this.datepickerVisible = true;
      });
      // this.getClientRfLast();
      this.getClientRf();
    },
    toggleEditRFP(selectedTableRowData) {
      const { originalIndex, nativeData: { mac, mac_addr } } = selectedTableRowData;

      // legacy
      // чтобы в датапикере перерисовалась дата скрываем его, а потом показываем
      this.datepickerVisible = false;
      // this.$set(this.drPickerDateForRF, 'startDate', moment().startOf('hour').valueOf());
      // this.$set(this.drPickerDateForRF, 'endDate', moment().endOf('hour').valueOf());
      this.$store.commit('setClientEditForRFPIndex', String(originalIndex));
      this.$store.commit('setClientEditForRFPMac', mac);
      this.$store.commit('setClientEditForRFPMacAddr', mac_addr);
      this.$nextTick(() => {
        // чтобы в датапикере перерисовалась дата
        this.datepickerVisible = true;
      });
      // this.getClientRfLast();
      this.getClientRf();
    },
    searchInputHandler(value) {
      this.disableEditModeFromSearchInputHandler();

      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClientsV2(this, 'spinner');
      }, 1000);
    },
    searchInputHandlerForRFP(value) {
      this.disableEditModeFromSearchInputHandlerForRFP();

      this.searchValueForRFP = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClientsForRFP(this, 'spinner');
      }, 1000);
    },
    limitInputHandlerForRFP() {
      this.disableEditModeFromSearchInputHandlerForRFP();

      // this.searchValueForRFP = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // this.refreshEvents();
        clientService.refreshClientsForRFP(this, 'spinner');
      }, 1000);
    },
    datePickerInputHandlerForRFP() {
      this.disableEditModeFromSearchInputHandlerForRFP();

      // this.searchValueForRFP = value;
      this.searchSpinner = true;
      clientService.refreshClientsForRFP(this, 'spinner');
    },
    refreshClientsListQuery() {
      this.canLoadMore = true;
      this.offset = 0;
      clientService.refreshClientsV2(this, 'spinner');
    },
    refreshClientsRFPListQuery() {
      this.canLoadMoreRFP = true;
      this.offsetRFP = 0;
      clientService.refreshClientsRFP(this, 'spinner');
    },
    showAllClients() {
      this.filters.statusFilter = '';
      this.canLoadMore = true;
      clientService.refreshClientsV2(this, 'spinner');
    },
    showAllRFPClients() {
      // this.filtersRFP.statusFilter = '';
      this.canLoadMoreRFP = true;
      clientService.refreshClientsRFP(this, 'spinner');
    },
    loadMore() {
      this.offset++;
      clientService.getPaginatedClientsV2(this, this.limit, this.offset);
    },
    loadMoreRFP() {
      this.offsetRFP++;
      clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = '';
      }
      if (filterName === 'typeFilter' && value === 'wired') {
        this.filters.frequencyFilter = '';
      }
      this.refreshClientsListQuery();
    },
    disableEditMode() {
      this.$store.commit('setClientEditIndex', null);
      this.$store.commit('setClientEditMac', null);
      this.$store.commit('setClientEditMacAddr', null);
    },
    disableEditModeForRFP() {
      this.$store.commit('setClientEditForRFPIndex', null);
      this.$store.commit('setClientEditForRFPMac', null);
      this.$store.commit('setClientEditForRFPMacAddr', null);
    },
    cpeNamebyId(id) {
      const cpeNamebyId = commonService.cpeNamebyId(id);
      return cpeNamebyId;
    },
    wlanNamebyId(id) {
      const wlanNamebyId = commonService.wlanNamebyId(id);
      return wlanNamebyId;
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    },
    formatToCSV(clients) {
      if (!clients) {
        return [];
      }

      const csvClients = JSON.parse(JSON.stringify(clients));

      return csvClients.map((client) => ({
        Type: client.Type ? client.Type : '',
        MAC: client.mac ? this.getFormatMac(client.mac) : '',
        IP: client.ip ? client.ip : '',
        Authorized: !!client.username,
        Description: client.data.description ? client.data.description : '',
        CPE: client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '',
        Frequency: client.Type !== 'wired' && client.freq ? client.freq : '',
        Channel: client.Type !== 'wired' && client.channel ? client.channel : '',
        RSSI: client.Type !== 'wired' && client.rssi ? client.rssi : '',
        'Ethernet Interface': client.Type === 'wired' && client.radio_id ? client.radio_id : '',
        WLAN: client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : ''
      }));
    },
    disableEditModeFromSearchInputHandler() {
      this.$store.commit('setClientEditIndex', null);
      this.$store.commit('setClientEditMac', null);
      this.$store.commit('setClientEditMacAddr', null);
      this.$nextTick(() => {});
    },
    disableEditModeFromSearchInputHandlerForRFP() {
      this.$store.commit('setClientEditForRFPIndex', null);
      this.$store.commit('setClientEditForRFPMac', null);
      this.$store.commit('setClientEditForRFPMacAddr', null);
      this.$nextTick(() => {});
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   this.filters.statusFilter = 'CONNECTED';
  //   this.filters.typeFilter = 'other';
  //   this.$store.commit('setClientsList', false);
  //   this.$store.commit('setClientsRFPList', false);
  //   this.disableEditMode();
  //   this.disableEditModeForRFP();
  //   if (this.$route.params.id) {
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.id;
  //     this.searchInputHandler(this.searchValue);
  //   } else {
  //     clientService.getPaginatedClients(this, this.limit, this.offset);
  //     clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
  //   }
  // }
    created() {
    let inHeaderLocationSelectedApplying = true;
    this.$store.commit('setCpesList', false);
    this.filters.statusFilter = 'CONNECTED';
    this.filters.typeFilter = 'other';
    this.$store.commit('setClientsList', false);
    this.$store.commit('setClientsRFPList', false);
    this.disableEditMode();
    this.disableEditModeForRFP();
    locationService.getLocations(this);
    if (this.$route.params.id) {
      this.filters.statusFilter = '';
      this.searchValue = this.$route.params.id;
      this.searchInputHandler(this.searchValue);
    } else {
      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        // if (!locationForFiltering || locationForFiltering === '') {
        //   locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        // }
        if (!locationForFiltering && locationForFiltering === '') {
          locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
          this.filters.location = locationForFiltering
        } else {
          this.filters.location = locationForFiltering
          this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        }

      //   if (locationForFiltering === 'All locations') {
      //   this.locationInFiltersData = [];
      // } else {
      //   this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      // }

        // this.filters.location = locationForFiltering
        this.filters.with_childs = withChildsForFiltering;
      }

      clientService.getPaginatedClientsV2(this, this.limit, this.offset);
      clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
    }
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      clientService.refreshClientsV2(this, 'spinner');
      clientService.refreshClientsForRFP(this, 'spinner');
      // clientService.getPaginatedClientsV2(this, this.limit, this.offset);
      // clientService.getPaginatedClientsRFP(this, this.limitRFP, this.offsetRFP);
    });
  }
};
</script>

<style lang="scss" scoped>
.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}

.emptystate-table-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.muted {
  color: var(--gray-light);
}

.client {
  display: flex;
}

.client-list,
.client-edit {
  transition: width 0.2s;
  will-change: transform;
}

.client-list {
  display: inline-block;
  width: 100%;
}

.client-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.client-edit .card-header {
  border: 0px;
}

.edit-visible .client-list {
  transition: width 0.2s;
  margin-right: 10px;
  min-width: 230px;
  width: 15%;
  will-change: transform;
}

.edit-visible .client-edit {
  transition: width 0.2s;
  min-width: 400px;
  width: 85%;
  will-change: transform;
}

.client-row {
  transition: background 0.15s;
  cursor: pointer;
}

.client-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.client-row.selected {
  background: #d9e3ec;
}

.client-state {
  display: flex;
  flex-direction: row;

  &--column {
    flex-direction: column;
  }

  &__main {
  }
}

.clients-camera-button {
  cursor: pointer;
  padding-left: 0.5rem;
}
.clients-camera-button:hover i {
  transition: transform 0.2s;
}

.clients-camera-button:hover i {
  transform: scale(1.4);
}

.badge-client {
  position: relative;
  white-space: nowrap;
}

.client-name {
  white-space: nowrap;
  position: relative;
  & i {
    position: absolute;
    display: block;
    width: 15px;
    top: 2px;
    left: -38px;
  }

  & i.fa-mobile {
    /*left: 3px;*/
  }

  & .badge-client {
    margin-left: 40px;
  }
}
</style>
<style>
                    .input-group-for-location-select-in-monitoringClientRF--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringClientRF--filters .select__list-container {
    width: 400px;
  }
</style>


<style lang="scss" scoped>
@media (max-width: 1130px) {
  .client-list {
    min-width: 870px;
  }

  .edit-visible .client-list {
    min-width: 230px;
  }
}

.description-row {
  word-break: break-word;
  overflow: hidden;
}

.client-info {
  display: flex;
}

.client-info-general,
.client-info-authorization {
  flex: 1;
}

.table-column-switcher {
  position: absolute;
  right: 0;
}

.loader-backdrop-clientsRF:after {
  position: absolute;
  top: 30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.clear-background-and-clear-padding-for-clients-rf {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
